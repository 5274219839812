import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { HeadingBasic } from "../../components/heading/heading"
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { NavTabs, TabPane, TabContent } from "../../components/ak-tabs/tabs"
import { Container } from "../../components/grid/grid"
import { CoverMain } from "../../components/cover-main/cover-main"
import { WpGroup } from "../../components/utils/render-functions"

import Document from "../../components/document/document"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["node"]["acfFile"][key]] =
        result[currentValue["node"]["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

const compareCompanyName = (a, b) => {
  const reportA = a.key
  const reportB = b.key

  let comparison
  if (reportA > reportB) {
    comparison = 1
  } else if (reportA < reportB) {
    comparison = -1
  }

  return comparison
}

const compareByYear = (a, b) => {
  const reportA = a.name
  const reportB = b.name

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

const Reports = ({ data }) => {
  const dataGroupedByCompany = data && groupBy(data, "company")
  const dataArr =
    (dataGroupedByCompany &&
      Object.keys(dataGroupedByCompany).map(key => [
        key,
        dataGroupedByCompany[key],
      ])) ||
    []

  let documentItems = []

  dataArr.length > 0 &&
    dataArr.map((dataItem, i) => {
      let releaseItems = []
      dataItem[1].map(item =>
        releaseItems.push({
          title: item.node.title || "Title",
          linkTo:
            item.node.acfFile.file !== null &&
            item.node.acfFile.file.mediaItemUrl,
          fileSize:
            item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
          fileId:
            item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
          date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
            item.node.date
          ).format("HH:mm")}`,
        })
      )

      documentItems.push(
        <div key={`${dataItem[0]}-${i}`} className="mb-5">
          <Document
            section={dataItem[0] !== "null" && dataItem[0]}
            data={releaseItems}
          />
        </div>
      )

      return 1
    })

  return (
    <>{documentItems.length > 0 && documentItems.sort(compareCompanyName)}</>
  )
}

const CorporateGovernence = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 738) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "corporate-governance") {
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        piagam: fileCategory(id: "piagam", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        rups: fileCategory(id: "rups", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        rupslb: fileCategory(id: "rupslb", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        rupst: fileCategory(id: "rupst", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
        anggaran: fileCategory(id: "anggaran", idType: SLUG) {
          slug
          filesPdf(first: 500) {
            edges {
              cursor
              node {
                title
                acfFile {
                  company
                  year
                  file {
                    databaseId
                    guid
                    filesize
                    mediaItemUrl
                    date
                  }
                }
                date
              }
            }
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  const rupsGroupedByYear =
    data.wordPress.rups &&
    data.wordPress.rups.filesPdf.edges.length > 0 &&
    groupBy(data.wordPress.rups.filesPdf.edges, "year")

  const rupslbGroupedByYear =
    data.wordPress.rupslb &&
    data.wordPress.rupslb.filesPdf.edges.length > 0 &&
    groupBy(data.wordPress.rupslb.filesPdf.edges, "year")

  const rupstGroupedByYear =
    data.wordPress.rupst &&
    data.wordPress.rupst.filesPdf.edges.length > 0 &&
    groupBy(data.wordPress.rupst.filesPdf.edges, "year")

  const anggaranGroupedByYear =
    data.wordPress.anggaran &&
    data.wordPress.anggaran.filesPdf.edges.length > 0 &&
    groupBy(data.wordPress.anggaran.filesPdf.edges, "year")

  let listAllRupsGrouped = []
  listAllRupsGrouped.push({...rupsGroupedByYear, ...rupslbGroupedByYear, ...rupstGroupedByYear})

  let piagamItems = []

  data.wordPress.piagam.filesPdf.edges.length > 0 &&
    data.wordPress.piagam.filesPdf.edges.map(item => {
      piagamItems.push({
        title: item.node.title || "Title",
        linkTo:
          item.node.acfFile.file !== null &&
          item.node.acfFile.file.mediaItemUrl,
        fileId:
          item.node.acfFile.file != null && item.node.acfFile.file.databaseId,
        fileSize:
          item.node.acfFile.file !== null && item.node.acfFile.file.filesize,
        date: `${moment(item.node.date).format("DD MMMM YYYY")} • ${moment(
          item.node.date
        ).format("HH:mm")}`,
      })
      return 1
    })

  let allRupsTab = []

  const allRupsArr =
    (listAllRupsGrouped[0] &&
      Object.keys(listAllRupsGrouped[0]).map(key => [
        key,
        listAllRupsGrouped[0][key]
      ])) ||
    []

  allRupsArr.length > 0 &&
  allRupsArr.map(item => {
    allRupsTab.push({ name: item[0], target: `allRups${item[0]}`})

    return 1
  })

  let rupsTab = []

  const rupsArr =
    (rupsGroupedByYear &&
      Object.keys(rupsGroupedByYear).map(key => [
        key,
        rupsGroupedByYear[key],
      ])) ||
    []

  rupsArr.map(item => {
    rupsTab.push({ name: item[0], target: `rups${item[0]}` })

    return 1
  })

  let rupslbTab = []

  const rupslbArr =
    (rupslbGroupedByYear &&
      Object.keys(rupslbGroupedByYear).map(key => [
        key,
        rupslbGroupedByYear[key],
      ])) ||
    []

  rupslbArr.map(item => {
    rupslbTab.push({ name: item[0], target: `rupslb${item[0]}` })
    return 1
  })

  let rupstTab = []

  const rupstArr =
    (rupstGroupedByYear &&
      Object.keys(rupstGroupedByYear).map(key => [
        key,
        rupstGroupedByYear[key],
      ])) ||
    []

  rupstArr.map(item => {
    rupstTab.push({ name: item[0], target: `rupst${item[0]}` })
    return 1
  })

  let anggaranTab = []

  const anggaranArr =
    (anggaranGroupedByYear &&
      Object.keys(anggaranGroupedByYear).map(key => [
        key,
        anggaranGroupedByYear[key],
      ])) ||
    []

  anggaranArr.map(item => {
    anggaranTab.push({ name: item[0], target: `anggaran${item[0]}` })
    return 1
  })

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt="General Information"
          className="cover-md"
        >
          <Breadcrumbs path={location.uri} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress?.map((item, i) => {
        switch (item.name) {
          case "core/group":
            return (
              <div key={`core/group-${i}`} className="py-main">
                <WpGroup data={item} />
              </div>
            )
          case "core/paragraph":
            return (
              <section key={`core/paragraph-${i}`} className="py-main">
                <div className="container container-md">
                  <HeadingBasic
                    text={[
                      item.attributes.content ||
                        "We are committed to practicing good corporate<br>governance in accordance with the corporate<br>governance guidelines issued by the Financial Services Authority in every line of our Company’s operations.",
                    ]}
                    className="mb-0"
                  />
                </div>
              </section>
            )
          default:
            return <div key={`empty-block-${i}`}></div>
        }
      })}

      {anggaranTab.length === anggaranArr.length && anggaranTab.length > 0 ? (
        <section className="anggaran-section">
          <div className="container">
            <HeadingBasic h2="Anggaran Dasar Perseroan" />
          </div>

          <Container>
            <NavTabs
              identifier="anggaran"
              scrollable
              data={anggaranTab.sort(compareByYear)}
            />
          </Container>
          {anggaranTab.map((item, i) => {
            return(
              <TabContent>
                <TabPane
                  identifier="anggaran"
                  key={`anggaran${item.name}-${i}`}
                  id={`${item.target}`}
                  className={`py-lg-5 pt-5 pt-lg-0 ${i === 0 ? "active" : ""}`}
                >
                  <Reports
                    data={anggaranGroupedByYear && anggaranGroupedByYear[item.name]}
                  />
                </TabPane>
              </TabContent>
            )
          })}
        </section>
      ) : null}

      {piagamItems.length > 0 && (
        <section className="piagam-section mb-5">
          <Document title="Piagam" data={piagamItems} />
        </section>
      )}

      {allRupsTab.length === allRupsArr.length && allRupsTab.length > 0 ? (
        <section className="allRups-section">
          <div className="container">
            <HeadingBasic h2="RUPS" />
          </div>

          <Container>
            <NavTabs
              identifier="allRups"
              scrollable
              data={allRupsTab.sort(compareByYear)}
            />
          </Container>
            
          {allRupsTab.map((item, i) => {
            return (
              <TabContent>
                <TabPane
                  identifier="allRups"
                  key={`allRups${item.name}-${i}`}
                  id={`${item.target}`}
                  className={`py-lg-5 pt-5 pt-lg-0 ${i === 0 ? "active" : ""}`}
                >
                  <Reports
                    data={listAllRupsGrouped[0] && listAllRupsGrouped[0][item.name]}
                  />
                </TabPane>
              </TabContent>
            )
          })}
        </section> 
      ) : null }

      {/* <section className="rupslb-section">
        <div className="container">
          <HeadingBasic h2="RUPS & RUPSLB" />
        </div>

        {rupslbTab.length === rupslbArr.length && (
          <Container>
            <NavTabs
              identifier="rupslb"
              scrollable
              data={rupslbTab.sort(compareByYear)}
            />
          </Container>
        )}
        {rupslbTab.length === rupslbArr.length &&
          rupslbTab.map((item, i) => {
            return (
              <TabContent>
                <TabPane
                  identifier="rupslb"
                  key={`rupslb${item.name}-${i}`}
                  id={`${item.target}`}
                  className={`py-lg-5 pt-5 pt-lg-0 ${i === 0 ? "active" : ""}`}
                >
                  <Reports
                    data={rupslbGroupedByYear && rupslbGroupedByYear[item.name]}
                  />
                </TabPane>
              </TabContent>
            )
          })}
      </section> */}

      {/* <section className="rupst-section">
        <div className="container">
          <HeadingBasic h2="RUPST" />
        </div>

        {rupstTab.length === rupstArr.length && (
          <Container>
            <NavTabs
              identifier="rupst"
              scrollable
              data={rupstTab.sort(compareByYear)}
            />
          </Container>
        )}
        {rupstTab.length === rupstArr.length &&
          rupstTab.map((item, i) => {
            return (
              <TabContent>
                <TabPane
                  identifier="rupst"
                  key={`rupst${item.name}-${i}`}
                  id={`${item.target}`}
                  className={`py-lg-5 pt-5 pt-lg-0 ${i === 0 ? "active" : ""}`}
                >
                  <Reports
                    data={rupstGroupedByYear && rupstGroupedByYear[item.name]}
                  />
                </TabPane>
              </TabContent>
            )
          })}
      </section> */}
    </Layout>
  )
}

export default CorporateGovernence
